// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'test',
  production: false,
  apiUrl: 'https://api.qa.naic.org/ofrs-api-core',
  auth: {
    clientId: '0oa1sz38i5octUvlV0h8',
    issuer: 'https://authenticate-nonprod.naic.org/oauth2/default',
    authorizationEndpoint: 'https://authenticate-nonprod.naic.org/oauth2/default/v1/authorize',
    tokenEndpoint: 'https://authenticate-nonprod.naic.org/oauth2/v1/token',
    redirectBaseUrl: 'https://ofrs-test.naic.org/welcome',
    logoutUrl: 'https://ofrs-test.naic.org',
    userInfoEndpoint: 'https://authenticate-nonprod.naic.org/userinfo',
    session_popup_time: 300, // 5 min
    session_idle_time: 1500 // 25 min
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
